<template>
  <div>
    <CommonsNotifier />
    <ModalsContainer />
    <NuxtPwaManifest />

    <CommonsModalsStatusExtrationModal :from-revision="true" />

    <div class="admin_layout_container scrollbar">
      <LayoutAdminNavbar class="navbar" />
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ModalsContainer } from "vue-final-modal";

const { isApple } = useDevice();

useHead({
  bodyAttrs: {
    class: isApple ? "" : "designed-scrollbar",
  },
});

const authentificationStore = useAuthentificationStore();
const societyStore = useSocietyStore();
const route = useRoute();

try {
  if (window?.$crisp) {
    window.$crisp.push(["do", "chat:hide"]);
  }
} catch (error) {
  console.error(error);
}

const routeSocietyId = computed(() =>
  route.params.id ? parseInt(route.params?.id as string) : null,
);

onMounted(async () => {
  if (
    isDefined(routeSocietyId.value) &&
    (isNotDefined(societyStore.society) || societyStore.society.id! !== routeSocietyId.value)
  ) {
    await authentificationStore.connectTo(routeSocietyId.value, route.path);
  }
});
</script>

<style lang="scss" scoped>
.admin_layout_container {
  max-height: 100vh;
  position: relative;
}
</style>
